import { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Link } from "react-scroll";

const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const pageRefresh = () => {
    window.location.reload();
    window.scrollTo(0, 0);
  };

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        className={`${styles.__sidebar} ${
          isOpen ? styles.__show : styles.__hide
        }`}
      >
        <img
          onClick={toggleSidebar}
          src="../../images/icons/close.svg"
          alt="close"
        />
        <div className={styles.__nav_links}>
          <Link
            onClick={toggleSidebar}
            to="hero"
            spy={true}
            smooth={true}
            offset={-22}
            duration={500}
          >
            Le Château
          </Link>
          <Link
            onClick={toggleSidebar}
            to="espaces"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
          >
            Espaces
          </Link>
          <Link
            onClick={toggleSidebar}
            to="presta"
            spy={true}
            smooth={true}
            offset={10}
            duration={500}
          >
            Activités
          </Link>
          {/* <Link
            onClick={toggleSidebar}
            to="contact"
            spy={true}
            smooth={true}
            offset={10}
            duration={500}
          >
            Accès
          </Link> */}
          <Link
            onClick={toggleSidebar}
            to="contact"
            spy={true}
            smooth={true}
            offset={10}
            duration={500}
          >
            Accès & Contact
          </Link>
        </div>
      </div>
      <div
        className={`${styles.__header} ${
          scrollPosition < 100 ? styles.__rm_header : ""
        }`}
      >
        <div className={styles.__details}>
          <img
            onClick={toggleSidebar}
            className={styles.__burger}
            src="../images/icons/menu-icon.svg"
            alt="icon menu"
          />
          <img
            onClick={pageRefresh}
            className={`${styles.__logo} ${
              scrollPosition < 100 ? styles.__hide_logo : ""
            }`}
            src="../images/logo/logo.svg"
            alt="logo"
          />
          {/* <img
            className={styles.__contact}
            src="../images/icons/contact-icon.svg"
            alt="contact icon"
          /> */}
          {/* <p className={`${styles.__contact} main_para`}>CONTACT</p> */}
        </div>
      </div>
    </>
  );
};

export default Header;
