import styles from "./styles.module.scss";
import Legal from "../../pages/mentions-legales";

const Modal = ({ setIsOpen }) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className={styles.__overlay} onClick={closeModal}></div>
      <div className={styles.__modal}>
        <div className={styles.__head}>
          <img
            onClick={closeModal}
            src="../../images/icons/close.svg"
            alt="close"
          />
        </div>
        <div className={styles.__container}>
          <Legal />
        </div>
      </div>
    </>
  );
};

export default Modal;
