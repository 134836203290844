import { useState } from "react";
import Button from "../Button";
import styles from "./styles.module.scss";

const Study = () => {
  const [index, setIndex] = useState(1);

  const handleTabs = (i: any) => {
    setIndex(i);
  };

  return (
    <div className={styles.__study} id="presta">
      <div className={styles.__container}>
        <img src="../../images/logo/logo-shield.svg" alt="shield" />
        <div className={styles.__right}>
          <h4 className="h4">Activités</h4>
          <p className="main_para">
            Proche de toutes les commodités, la localisation avantageuse du
            château permet également un accès simplifié à de nombreuses
            activités de pleine nature.
          </p>
          <p className={`${styles.__case_studies} main_para`}>A proximité</p>
          <div className={styles.__underline}></div>
          <div className={styles.__tabs}>
            <Button
              content="Facilités"
              handleClick={() => handleTabs(1)}
              classNameBtn={`${index === 1 ? styles.__active : ""}`}
            />
            <Button
              content="A visiter"
              handleClick={() => handleTabs(2)}
              classNameBtn={`${index === 2 ? styles.__active : ""}`}
            />
            <Button
              content="Loisirs"
              handleClick={() => handleTabs(3)}
              classNameBtn={`${index === 3 ? styles.__active : ""}`}
            />
          </div>
          <div className={styles.__lists}>
            <ul>
              <li className={`${index === 1 ? styles.__show : styles.__hide}`}>
                Gare: 12 km
              </li>
              <li className={`${index === 1 ? styles.__show : styles.__hide}`}>
                Commerces: 6 km
              </li>
              <li className={`${index === 1 ? styles.__show : styles.__hide}`}>
                Plage: 6 km
              </li>
              <li className={`${index === 1 ? styles.__show : styles.__hide}`}>
                Supermarché: 2km
              </li>
            </ul>

            <ul>
              <li className={`${index === 2 ? styles.__show : styles.__hide}`}>
                Dieppe: 12 km
              </li>
              <li className={`${index === 2 ? styles.__show : styles.__hide}`}>
                Varengeville-sur-Mer: 15 km
              </li>
              <li className={`${index === 2 ? styles.__show : styles.__hide}`}>
                Le Tréport: 20 km
              </li>
              <li className={`${index === 2 ? styles.__show : styles.__hide}`}>
                Mers-les-Bains: 21 km
              </li>
            </ul>

            <ul className={styles.__col_2}>
              <li className={`${index === 3 ? styles.__show : styles.__hide}`}>
                Piscine: 6 km
              </li>
              <li className={`${index === 3 ? styles.__show : styles.__hide}`}>
                Plan d'eau: 8 km
              </li>
              <li className={`${index === 3 ? styles.__show : styles.__hide}`}>
                Pêche: 8 km
              </li>
              <li className={`${index === 3 ? styles.__show : styles.__hide}`}>
                Tennis: 6 km
              </li>
              <li className={`${index === 3 ? styles.__show : styles.__hide}`}>
                Équitation: 2 km
              </li>
              <li className={`${index === 3 ? styles.__show : styles.__hide}`}>
                Golf: 12 km
              </li>
              <li className={`${index === 3 ? styles.__show : styles.__hide}`}>
                Canoë-kayak: 12 km
              </li>
              <li className={`${index === 3 ? styles.__show : styles.__hide}`}>
                Randonnée pédestre: 6 km
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Study;
