import Home from "./pages/index";
import Legal from "../src/pages/mentions-legales";

import {
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import "./styles/global.scss";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mentions-legales" element={<Legal />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
