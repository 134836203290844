import styles from "./styles.module.scss";

interface Props {
  content: string;
  handleClick?: any;
  icon?: any;
  classNameBtn?: any;
}

const Button = ({ content, handleClick, icon, classNameBtn }: Props) => {
  return (
    <button
      onClick={handleClick}
      className={`${styles.__button} ${classNameBtn}`}
    >
      {content}
      {icon ? icon : null}
    </button>
  );
};

export default Button;
