import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import styles from "./styles.module.scss";

const Map = () => {
  const containerStyle = {
    width: "500px",
    height: "300px",
  };

  const center = {
    lat: 49.91947457962559,
    lng: 1.2005748232833109,
  };

  return (
    <div className={styles.__map}>
      <h4 className="h4">Accès</h4>
      <div className={styles.__access_wrapper}>
        <div className={styles.__access}>
          <p className="light_para">
            Accès Routier via autoroute A151
            <a
              className="light_para"
              href="https://fr.mappy.com/plan#/5%20Chemin%20du%20Manoir%2076630%20Sauchay"
              target={"blank"}
            >
              Voir le trajet
            </a>
          </p>
          <p className="light_para">
            Accès Ferroviaire via gare de Dieppe
            <a
              className="light_para"
              href="https://www.sncf-connect.com/app/home/search?userInput=gare+dieppe&destinationId="
              target={"_blank"}
              rel="noreferrer"
            >
              Venir en train
            </a>
          </p>
        </div>
        <div className={styles.__links}>
          <a
            className="light_para"
            href="https://fr.mappy.com/plan#/5%20Chemin%20du%20Manoir%2076630%20Sauchay"
            target={"blank"}
          >
            Voir le trajet
          </a>
          <a
            className="light_para"
            href="https://www.sncf-connect.com/app/home/search?userInput=gare+dieppe&destinationId="
            target={"_blank"}
            rel="noreferrer"
          >
            Venir en train
          </a>
        </div>
      </div>
      <LoadScript googleMapsApiKey="AIzaSyArrKloUGJTi2kZQwBV4CdzIT430KX7qWM">
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={11}>
          <Marker position={center} />
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default Map;
