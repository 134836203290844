import styles from "../../styles/staticPages.module.scss";

const Legal = () => {
  return (
    <div className={styles.__container}>
      <div className={styles.__head}>
        <h2 className="h2">Mentions Légales</h2>
        <p className="light_para">
          Conformément aux dispositions de l’article 6-I 1° de la loi n°2004-575
          du 21 juin 2004 relative à la confiance dans l’économie numérique.
        </p>
      </div>
      <hr />
      <div className={styles.__section}>
        <h4 className="h4">Editeur :</h4>
        <p className="light_para">
          Le site{" "}
          <a href="https://www.chateaudesauchay.com">
            https://www.chateaudesauchay.com
          </a>{" "}
          est édité par la société Château de Sauchay SCI
        </p>
        <p className="light_para">
          Adresse : 5, Chemin du Manoir 76630 Sauchay
        </p>
        <p className="light_para">Siret : 79098894300011</p>
        <p className="light_para">Code Ape : 6820B</p>
        <p className="light_para">
          Contact :
          <a href="mailto:contact@chateaudesauchay.com">
            contact@chateaudesauchay.com
          </a>
        </p>
      </div>
      <div className={styles.__section}>
        <h4 className="h4">Directeur de la publication :</h4>
        <p className="light_para">
          Le directeur de la publication est Yassine Kodadi: CEO Slickk
        </p>
      </div>
      <div className={styles.__section}>
        <h4 className="h4">Hébergement :</h4>
        <p className="light_para">
          Le site{" "}
          <a href="https://www.chateaudesauchay.com">
            https://www.chateaudesauchay.com
          </a>{" "}
          est hébergé par la société Amazon Web Services ( AWS )
        </p>

        <p className="light_para">
          Adresse : 31 PL DES COROLLES 92400 COURBEVOIE
        </p>
        <p className="light_para">Siret : 83100133400034</p>
        <p className="light_para">
          Contact : Formulaire de contact disponible à l’adresse Contact AWS
        </p>
      </div>
      <div className={styles.__section}>
        <h4 className="h4">Conception :</h4>
        <p className="light_para">
          Le site{" "}
          <a href="https://www.chateaudesauchay.com">
            https://www.chateaudesauchay.com
          </a>{" "}
          est conçu par la société SLICKK
        </p>
        <p className="light_para">
          Adresse : 20 B RUE LOUIS PHILIPPE 92200 NEULLY SUR SEINE
        </p>
        <p className="light_para">Siret : 89134120800025</p>
        <p className="light_para">Code Ape : 6201Z</p>
        <p className="light_para">Contact : contact@slickk.eu</p>
      </div>
    </div>
  );
};

export default Legal;
