import { useState } from "react";
import styles from "./styles.module.scss";

const Overlap = () => {
  const [selected, setSelected] = useState(null);
  const [current, setCurrent] = useState(0);

  const prevSlide = () => {
    setCurrent(current === 0 ? dataImg.length - 1 : current - 1);
  };

  const nextSlide = () => {
    setCurrent(current === dataImg.length - 1 ? 0 : current + 1);
  };

  const toggleAccordion = (index: any) => {
    if (selected === index) {
      return setSelected(null);
    }

    setSelected(index);
  };

  const dataImg = [
    { source: "../../images/slide_1.png" },
    { source: "../../images/slide_3.png" },
    { source: "../../images/slide_4.png" },
    { source: "../../images/slide_5.png" },
    { source: "../../images/slide_6.png" },
  ];
  let data = [
    {
      id: 0,
      headContent: "Salle de Cérémonie",
      bodyContent: `Le Château dispose d'une magnifique salle Normande de réception de 200 m². Cette salle de cérémonie présente un charmant style normand avec de belles poutres apparentes et des murs de brique de Marne. Une majestueuse cheminée orne la pièce et de larges baies vitrées laissent entrer la lumière du jour en offrant une vue privilégiée sur le parc.`,
    },
    {
      id: 1,
      headContent: "Salle de Conférence",
      bodyContent: `Le Château de Sauchay est doté d’une spacieuse salle de conférence en pierres et poutres apparentes. Trois arches permettent aux deux salles de communiquer.`,
    },
    {
      id: 2,
      headContent: "Le Parc",
      bodyContent: `Le parc, ses arbres centenaires et les pelouses parfaitement entretenues apportent une touche buccolique au décor. Une immense allée d'hortensias borde l'accès au château.
      `,
    },
    // {
    //   id: 3,
    //   headContent: "Chambres d’hôtes / gîtes",
    //   bodyContent: `Le Château de Sauchay dispose d'un gîte et de chambres d’hôtes. Pour un repos en toute quiétude.`,
    // },
  ];

  return (
    <div className={styles.__overlap} id="espaces">
      <div className={styles.__left}>
        <div className={styles.__slider_container}>
          {dataImg.map((el, index) => {
            return (
              <img
                className={
                  current === index ? styles.__show_img : styles.__hide_img
                }
                key={index}
                src={el.source}
                alt="castle"
              />
            );
          })}
        </div>
        <div className={styles.__slider_btn}>
          <img
            className={styles.__gallery}
            src="../../images/icons/left-arrow.svg"
            alt="left arrow"
            onClick={prevSlide}
          />
          <span>{current + 1}</span>
          <span className={styles.__dash}>-</span>
          <span>{dataImg.length}</span>
          <img
            loading="lazy"
            src="../../images/icons/right-arrow.svg"
            alt="left arrow"
            onClick={nextSlide}
          />
        </div>
      </div>

      <div className={styles.__right}>
        <h4 className="h4">Espaces</h4>
        <p className="light_para">
          Le domaine dispose d'installations modernes et rénovées dont la
          capacité d'accueil est de 170 convives maximum.
        </p>

        <div className={styles.__dropdown_container}>
          {data.map((el, index) => {
            return (
              <div key={index} className={styles.__dropdown}>
                <div
                  className={styles.__head}
                  onClick={() => toggleAccordion(index)}
                >
                  <p className={`${styles.__head_text} light_para`}>
                    {el.headContent}
                  </p>
                  <span>{selected === index ? "-" : "+"}</span>
                </div>
                <div className={styles.__divider}></div>
                <p
                  className={`${
                    selected === index ? styles.__show : styles.__hide
                  } light_para ${styles.__content}`}
                >
                  {el.bodyContent}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Overlap;
