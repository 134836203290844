import styles from "./styles.module.scss";
import { Link } from "react-scroll";
import Modal from "../Modal/index";
import { useState } from "react";

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  isOpen
    ? (document.documentElement.style.overflowY = "hidden")
    : (document.documentElement.style.overflowY = "unset");

  return (
    <>
      {isOpen && <Modal setIsOpen={setIsOpen} />}
      <div className={styles.__footer}>
        <div className={styles.__container}>
          <div className={styles.__schedules_details}>
            <img
              className={styles.__logo}
              src="../../../images/logo/logo-illustr.svg"
              alt="logo"
            />
            <div className={styles.__details}>
              <div className={styles.__col_4}>
                <ul>
                  <li className="main_para">Le Château de Sauchay</li>
                  <li className="main_para">5 Chemin du Manoir</li>
                  {/* <li>
                    <p>EMAIL</p>
                  </li> */}
                  <li className="main_para">76630 Sauchay</li>
                  {/* <li>
                    <p>APPELEZ-NOUS</p>
                  </li> */}
                  <li className="main_para">contact@chateaudesauchay.com</li>
                </ul>
              </div>
            </div>
          </div>

          <div className={styles.__copyright}>
            <div className={styles.__links}>
              <Link
                to="hero"
                spy={true}
                smooth={true}
                offset={-22}
                duration={500}
              >
                Le Château
              </Link>
              <Link
                to="espaces"
                spy={true}
                smooth={true}
                offset={-22}
                duration={500}
              >
                Espaces
              </Link>
              <Link
                to="presta"
                spy={true}
                smooth={true}
                offset={-22}
                duration={500}
              >
                Activités
              </Link>
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-22}
                duration={500}
              >
                Accès & Contact
              </Link>
              <span>|</span>
              <p onClick={() => setIsOpen((prev) => !prev)}>Mentions Légales</p>
            </div>
            <h6 className={styles.__copyright_text}>
              &copy; 2022. Le Château de Sauchay. All Rights reserved. <a href="https://agence.slickk.eu/" target="_blank" rel="noopener noreferrer">Powered
              by Slickk</a>
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
