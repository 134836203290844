import styles from "./styles.module.scss";

const Rows = () => {
  return (
    <div className={styles.__rows}>
      <img src="../../images/light_shield.svg" alt="shield" />
      <div className={styles.__column}>
        <h6 className="h6">salle de cérémonie</h6>
        <p className="regular_para">
          Le château de Sauchay dispose d'une magnifique salle Normande de
          cérémonie.
        </p>
      </div>
      <div className={styles.__column}>
        <h6 className="h6">salle de conférence</h6>
        <p className="regular_para">
          Le château de Sauchay est doté d'une salle de conférence attenante à
          la salle Normande.
        </p>
      </div>
      <div className={styles.__column}>
        <h6 className="h6">le parc</h6>
        <p className="regular_para">
          Le château de Sauchay possède un grand parc dont la végétation est
          parfaitement entretenue.
        </p>
      </div>
    </div>
  );
};

export default Rows;
