import styles from "./styles.module.scss";

const Story = () => {
  return (
    <div className={styles.__story}>
      <div className={styles.__left}>
        <img
          loading="lazy"
          className={styles.__img_1}
          src="../../images/castle_view_3.png"
          alt="castle"
        />
        <img
          loading="lazy"
          className={styles.__img_2}
          src="../../images/castle_view_6.png"
          alt="castle"
        />
        <img
          loading="lazy"
          className={styles.__img_3}
          src="../../images/castle_view_1.png"
          alt="castle"
        />
      </div>
      <div className={styles.__right}>
        <p className="main_para">Territoire "Monts et Vallées"</p>
        <h4 className="h4">
          La plus agréable demeure de caractère de Haute Normandie
        </h4>
        <p className={`${styles.__text} regular_para`}>
          L’authenticité et le charme d’antan sont les maîtres mots d'un séjour
          au château de Sauchay.
        </p>
        <p className={`${styles.__text} regular_para`}>
          Ancienne grange dimière, le domaine de ce site historique est situé en
          Seine Maritime, non loin de Dieppe, sur la commune éponyme de Sauchay.
        </p>
        <p className={`${styles.__text} regular_para`}>
          A environ 200 km de Paris, la proximité des plages de la Manche en
          fait un endroit privilégié pour des séjours détente et nature.
        </p>
        <p className={`${styles.__text} regular_para`}>
          Andamera Corp est ravie d'accueillir sur ce site : Collaborateurs,
          Clients et Partenaires; à l'occasion de manifestations évènementielles
          ou de séminaires de présentation des marques du groupe.
        </p>
        <p className={`${styles.__punchline} main_para`}>
          Le cadre parfait pour un moment inoubliable !
        </p>
        <div className={styles.__underline}></div>
      </div>
    </div>
  );
};
export default Story;
