import styles from "./styles.module.scss";

const Divider = () => {
  return (
    <div className={styles.__divider}>
      <div className={styles.__container}>
        <div className={styles.__circle}></div>
        <div className={styles.__circle}></div>
        <div className={styles.__circle}></div>
      </div>
    </div>
  );
};

export default Divider;
