import styles from "./styles.module.scss";
import Map from "../Map/index";
import Button from "../Button";
import { useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import ReCAPTCHA from "react-google-recaptcha";
const { REACT_APP_RECAPTCHA } = process.env;

const Contact = () => {
  const [errorEmail, setErrorEmail] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorCaptcha, setErrorCaptcha] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(null);
  const [isSend, setIsSend] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaRef = useRef(null);

  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const [values, setValues] = useState({
    fullName: "",
    email: "",
    message: "",
  });

  const onChange = (e: any) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const verifyCaptcha = async (token) => {
    setCaptchaToken(token);
    if (token) {
      try {
        await fetch(`${process.env.REACT_APP_BACKEND_URL}verify-captcha`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            token: token,
          }),
        });
        setErrorCaptcha("");
      } catch (err) {
        captchaRef.current.reset();
        setCaptchaToken(null);
        console.error(err);
      }
    } else {
      setErrorCaptcha("Vous devez confirmer que vous n'êtes pas un robot.");
    }
  };

  const sendMail = async (e: any) => {
    e.preventDefault();

    if (!!REACT_APP_RECAPTCHA) {
      if (!captchaToken) {
        setErrorCaptcha("Vous devez confirmer que vous n'êtes pas un robot.");
      } else {
        setErrorCaptcha("");
      }
    }
    if (!values.fullName) {
      setErrorName("Ce champ est requis");
    } else {
      setErrorName("");
    }
    if (!values.message) {
      setErrorMessage("Ce champ est requis");
    } else {
      setErrorMessage("");
    }
    if (!values.email) {
      setErrorEmail("Ce champ est requis");
    } else {
      setErrorEmail("");
    }
    if (!values.email.match(validRegex) && values.email !== "") {
      setErrorEmail("Format d'adresse incorrect");
    }

    try {
      if (
        (!!REACT_APP_RECAPTCHA ? captchaToken : true) &&
        values.fullName &&
        values.email &&
        values.message &&
        values.email.match(validRegex)
      ) {
        const data = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}sendmail/`,
          {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: `fullName=${values.fullName}&email=${values.email}&message=${values.message}`,
          }
        );
        const response = await data.json();

        setIsSubmitting(response);

        setTimeout(() => {
          setIsSubmitting(null);
          if (response.data.name === "Error") {
            setRejected(true);
          }
          if (response.data[0].status === "sent") {
            setIsSend(true);
          } else {
            setRejected(true);
          }
        }, 3000);

        setValues({
          email: "",
          fullName: "",
          message: "",
        });
      }
    } catch (error) {
      console.log(error);
      setRejected(true);
    }
    setIsSend(false);
    setRejected(false);
  };

  return (
    <div id="contact" className={styles.__contact}>
      <Map />
      <div className={styles.__form}>
        <h4 className="h4">Contact</h4>
        <p className={`${styles.__mail} light_para`}>
          <a href="mailto: contact@chateaudesauchay.com">
            contact@chateaudesauchay.com
          </a>
        </p>

        {isSend && (
          <p className={`${styles.__success_message} light_para`}>
            Votre message a bien été envoyé{" "}
            <img src="../../images/icons/check.png" alt="sent" />
          </p>
        )}

        {rejected && (
          <p className={`${styles.__error_form} light_para`}>
            Une erreur est survenue{" "}
            <img src="../../images/icons/error.png" alt="sent" />
          </p>
        )}

        <form className={styles.__form_wrapper} onSubmit={(e) => sendMail(e)}>
          <div className={styles.__input_group}>
            <input
              id="fullName"
              name={"fullName"}
              type={"text"}
              placeholder={"Nom complet"}
              value={values.fullName}
              onChange={onChange}
            />
            <label className={styles.__form_label} htmlFor="fullName">
              Nom complet
            </label>
          </div>
          {errorName ? (
            <span className={`${styles.__error_message}`}>{errorName}</span>
          ) : (
            ""
          )}
          <div className={styles.__input_group}>
            <input
              id="email"
              name={"email"}
              type={"email"}
              placeholder={"Email"}
              value={values.email}
              onChange={onChange}
            />
            <label className={styles.__form_label} htmlFor="email">
              Email
            </label>
          </div>
          {errorEmail ? (
            <span className={`${styles.__error_message}`}>{errorEmail}</span>
          ) : (
            ""
          )}

          <div className={styles.__textarea_group}>
            <textarea
              id="message"
              placeholder="Message"
              name="message"
              value={values.message}
              onChange={onChange}
            ></textarea>
            <label className={styles.__textarea_label} htmlFor="message">
              Message
            </label>
          </div>

          {errorMessage ? (
            <span
              className={`${styles.__error_message} ${styles.__error_textarea}`}
            >
              {errorMessage}
            </span>
          ) : (
            ""
          )}
          <div className={styles.__captcha_sendbtn_section}>
            <div className={styles.__send_btn}>
              {" "}
              <Button
                content="Envoyer"
                icon={
                  isSubmitting ? (
                    <CircularProgress color="primary" size={20} />
                  ) : (
                    <img
                      src="../../images/icons/chevron-right.svg"
                      alt="icon"
                    />
                  )
                }
              />
            </div>
            {REACT_APP_RECAPTCHA && (
              <ReCAPTCHA
                sitekey={REACT_APP_RECAPTCHA}
                ref={captchaRef}
                onChange={verifyCaptcha}
              />
            )}
            {errorCaptcha && (
              <span
                className={`${styles.__error_message} ${styles.__error_textarea} ${styles.__error_captcha}`}
              >
                {errorCaptcha}
              </span>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
