import styles from "./styles.module.scss";

const Banner = () => {
  return (
    <div className={styles.__banner} id="hero">
      <div className={styles.__container}>
        <p className="main_para">À Sauchay, au coeur de la Normandie.</p>
        <h3 className="h3">Découvrez un château raffiné et prestigieux</h3>
      </div>
    </div>
  );
};

export default Banner;
