import HeroBanner from "../components/HeroBanner";
import Banner from "../components/Banner";
import Divider from "../components/Divider";
import Rows from "../components/Rows";
import Story from "../components/Story";
import Study from "../components/Study";
import Overlap from "../components/Overlap";
import Testimonials from "../components/Testimonials";
import Contact from "../components/Contact";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from "../styles/pages/home.module.scss";

const Home = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Header />
      <HeroBanner />
      <div className={"main_container"}>
        <Banner />
        <Divider />
        <Rows />
        <Story />
        <Overlap />
        <Study />
        {/* <Area /> */}
        <Contact />
        <Testimonials />
      </div>
      <div className={styles.__scroll_top} onClick={scrollToTop}>
        <img src="../../images/icons/arrow-up.png" alt="scroll" />
      </div>
      <Footer />
    </>
  );
};

export default Home;
