import styles from "./styles.module.scss";
import { useState } from "react";

const Testimonials = () => {
  const [current, setCurrent] = useState(0);

  const data = [
    {
      content: `C'est un très beau château, les gîtes sont grands et les chambres aussi. Vraiment idéal pour des familles. C'est calme, propre. Personnellement j'ai adoré le coin et mes enfants aussi. Nous avons passé trois jours exceptionnels. La plage est à 10mn et il y a beaucoup d'activités dans les alentours. Vraiment sympa !`,
      author: "Caroline M.",
    },
    {
      content:
        "C'est une demeure magnifique, intérieurement et extérieurement. Son aspect chaleureux et paisible, nous donne l'envie d'y rester indéfiniment. Le parc est très bien entretenu, c'est un plaisir de se détendre à l'ombre des marronniers.",
      author: "Alain D.",
    },
    {
      content: `Lieu enchanteur,  porteur d'Histoire et d'histoires… Les propriétaires ont transformé ce château en un lieu chaleureux, accueillant et de bon goût!
        A recommander absolument!`,
      author: "Catherine B.",
    },
  ];

  const nextSlide = () => {
    setCurrent(current === data.length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? data.length - 1 : current - 1);
  };

  return (
    <div className={styles.__testimonials}>
      <img className={styles.__bg} src="../../images/garden.png" alt="garden" />
      <img
        className={styles.__bg}
        src="../../images/portrait.png"
        alt="portrait"
      />
      <div className={styles.__container}>
        <div className={styles.__details}>
          <h3 className="h3">Avis Clients</h3>
          {data.map((el, index) => {
            return (
              <div
                key={index}
                className={`${
                  index === current ? styles.__active : styles.__slides
                } `}
              >
                {index === current && (
                  <>
                    <p className={`${styles.__content} light_para`}>
                      {el.content}
                    </p>
                    <p className={`${styles.__author} light_para`}>
                      {el.author}
                    </p>
                  </>
                )}
              </div>
            );
          })}

          <div className={styles.__slider_btn}>
            <img
              src="../../images/icons/left-arrow.svg"
              alt="left arrow"
              onClick={prevSlide}
            />
            <span>{current + 1}</span>
            <span className={styles.__dash}>-</span>
            <span>{data.length}</span>
            <img
              src="../../images/icons/right-arrow.svg"
              alt="left arrow"
              onClick={nextSlide}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
