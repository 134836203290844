import styles from "./styles.module.scss";
import { Link } from "react-scroll";

import "../../styles/global.scss";

const HeroBanner = () => {
  return (
    <div className={styles.__hero_banner}>
      <img
        className={styles.__logo}
        src="../../images/logo/logo.svg"
        alt="logo"
      />
      <div className={styles.__details}>
        <h1 className="h1">Un château d'exception</h1>
        <p className={`${styles.__para} light_para`}>
          Un domaine historique dans un cadre de charme et de détente
        </p>

        <Link to="hero" spy={true} smooth={true} offset={-22} duration={500}>
          <div className={styles.__scroll_to_section}>
            <p>EN SAVOIR PLUS</p>
            <img src="../../images/icons/right-arrow.svg" alt="Right Arrow"/>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default HeroBanner;
